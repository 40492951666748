import * as React from 'react'
import { Button, Container, Flex } from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'
import GameItemBottomBar from './gameItemBottomBar'
import eventTracking from '../services/eventTracking'

const GameItem = ({ game }) => {
	const { id, banner, min, max, duration, rounds } = game

	const afterClick = () => {
		eventTracking('show_howtoPlay', {
			"game_id": id
		})
		navigate(`/game/game-${id}/`)
	}
	return (
		<Container variant="game">
			<Flex direction="column" justifyContent="center">
				<Flex alignItems="center" justifyContent="center">
					<GatsbyImage
						alt="Game Banner"
						style={{ width: '100%' }}
						image={getImage(
							banner?.childImageSharp?.gatsbyImageData
						)}
					/>
				</Flex>
				<Flex justifyContent="center" mt="20px">
					<Button
						variant="medium"
						onClick={() => afterClick()}
					>
						How to play
					</Button>
				</Flex>
				<GameItemBottomBar
					min={min}
					max={max}
					duration={duration}
					rounds={rounds}
				/>
			</Flex>
		</Container>
	)
}

export default GameItem
