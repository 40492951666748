import React, { useEffect, useState } from 'react'
import BasePage from '../../../components/basePage'
import {
	Button,
	Text,
	Flex,
	Container,
	useToast,
	Box,
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	AccordionIcon
} from '@chakra-ui/react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../layout'
import { useQuery } from '@tanstack/react-query'
import { getMyGroups } from '../../../services/group'
import Spinner from '../../../components/spinner'
import GameItem from '../../../components/gameItem'

import moment from 'moment/moment'

const PostEvent = ({ userId }) => {
	const { isLoading, isError, data } = useQuery({
		queryKey: ['groups', userId],
		queryFn: () => getMyGroups(userId)
	})

	const gamesData = useStaticQuery(FetchAllQuery)
	const gamesNodes = gamesData?.allMarkdownRemark?.edges || []

	if (isLoading || isError) {
		return (
			<Layout title={'Post Event'}>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					height="100vh"
				>
					<Spinner />
				</Flex>
			</Layout>
		)
	}

	return (
		<Layout title="Post Event">
			<BasePage
				theme="dark"
				fullWidthActionBtns
				showBottombar
				noPadding
				flex
				largeTitle={
					<StaticImage
						width={380}
						alt="Logo"
						style={{ marginBottom: 24, width: '200px' }}
						src="../../images/logo-white.png"
					/>
				}
			>
				<Container maxW="md">
					<Text variant="m" color="white" textAlign="center" mb="8">
						Thank you for visiting us. <br />
						We hope you had a great time and look
						<br /> forward to seeing you soon.
					</Text>

					<Accordion allowToggle>
						<AccordionItem bg="darkGray" pt="4" mb="4">
							<AccordionButton
								display="flex"
								justifyContent="center"
								py="0"
							>
								<Text
									variant="lgg"
									color="white"
									align="center"
								>
									LEADERBOARDS
								</Text>
							</AccordionButton>

							<AccordionPanel
								alignSelf="center"
								bg="darkGray"
								px="4"
							>
								{data.map((group) => {
									return (
										<Link
											to={`/leaderboards/${group.id}/${group.groupMemberId}`}
										>
											<Box
												bgColor="pink"
												mb="10px"
												py="2"
											>
												<Text
													variant="m"
													color="black"
													textAlign="center"
													fontWeight="bold"
												>
													{moment(
														group.eventDate
													).format(
														'dddd Do MMMM YYYY'
													)}
												</Text>
												<Text
													variant="xs"
													fontWeight="regular"
													color="black"
													textAlign="center"
												>
													{group.name}
												</Text>
											</Box>
										</Link>
									)
								})}
							</AccordionPanel>

							<AccordionButton
								display="flex"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
							>
								<AccordionIcon
									color="white"
									width="12"
									height="10"
								/>
							</AccordionButton>
						</AccordionItem>

						<AccordionItem bg="darkGray" pt="4">
							<AccordionButton
								display="flex"
								justifyContent="center"
								py="0"
							>
								<Text
									variant="lgg"
									color="white"
									align="center"
								>
									GAMES
								</Text>
							</AccordionButton>

							<AccordionPanel alignSelf="center" bg="darkGray">
								{gamesNodes.map((item, index) => (
									<GameItem
										key={index}
										game={item.node?.frontmatter || {}}
									/>
								))}
							</AccordionPanel>

							<AccordionButton
								display="flex"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
							>
								<AccordionIcon
									color="white"
									width="12"
									height="10"
								/>
							</AccordionButton>
						</AccordionItem>
					</Accordion>
				</Container>
			</BasePage>
		</Layout>
	)
}

export default PostEvent

const FetchAllQuery = graphql`
	query {
		allMarkdownRemark(
			sort: { order: ASC, fields: frontmatter___id }
			filter: {
				frontmatter: { title: { ne: "" } }
				fields: { name: { ne: "game-0" } }
			}
		) {
			edges {
				node {
					frontmatter {
						id
						min
						max
						duration
						rounds
						desc
						banner {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
				}
			}
		}
	}
`
