import * as React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import PeopleSVG from '../icons/people.svg'
import ClockSVG from '../icons/clock.svg'
import RefreshSVG from '../icons/refresh.svg'

const GameItemBottomBar = ({ min, max, duration, rounds }) => (
	<Flex alignItems='center' justifyContent='center' mt='20px'>
		<Flex alignItems='center'>
			<PeopleSVG style={{ marginBottom: 1 }} />
			<Text
				variant='ml'
				color='white'
				lineHeight='25px'
				fontWeight='600'
				ml='4px'
			>
				{min} - {max}
			</Text>
		</Flex>
		<Flex alignItems='center' mx='18px'>
			<ClockSVG />
			<Text
				variant='ml'
				color='white'
				lineHeight='25px'
				fontWeight='600'
				ml='4px'
			>
				{duration} mins
			</Text>
		</Flex>
		<Flex alignItems='center'>
			<RefreshSVG />
			<Text
				variant='ml'
				color='white'
				lineHeight='25px'
				fontWeight='600'
				ml='4px'
			>
			{rounds}
			</Text>
		</Flex>
	</Flex>
)

export default GameItemBottomBar
